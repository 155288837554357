import React, { useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import LanguageIcon from '../icons/LanguageIcon';
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { Button, Dialog, DialogContent, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';

export const languageMap = {
  'en-US': "English",
  'pt-BR': "Português",
  'es': "Español",
  'fr-FR': "Français (France)",
  'it-IT': "Italiano",
  'de-DE': "Deutsch",
  'nl-NL': "Nederlands",
  'fr-CA': "Français (Canada)",
  'pl-PL': "Polski",
  'sv-SE': "Svenska",
  'tr-TR': "Türkçe",
  'ms-MY': "Bahasa Melayu",
  'nb-NO': "norsk",
  'zh-CN': "简体中文",
  'zh-TW': "繁體中文",
  'hi-IN': 'हिन्दी',
  'ko-KR': '한국어',
  'ru-RU': 'Русский',
  'th-TH': 'ไทย',
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: '24px'
    },
    divider: {
      height: '100%',
      width: '0',
      border: '1px solid #fff',
      borderRadius: '1px',
      opacity: '0.4',
      marginRight: '24px',
    },
    icon: {
      display: 'block',
      color: '#fff',
    },
    language: {
      display: 'block',
      color: '#fff',
      fontSize: '13px',
      lineHeight: '17px',
      textTransform: 'uppercase',
      fontWeight: '400'
    },
    switcher: {
      display: 'flex',
      gap: '8px',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
    },
    modalContent: {
      padding: '24px !important',
      borderRadius: '16px',
      width: '375px',
    },
    modalTitle: {
      margin: '0 0 16px',
      fontSize: '21px',
      lineHeight: '30px',
      fontWeight: '500',
    },
    radioGroup: {
      width: '100%',
      marginBottom: '16px',
    },
    radioItem: {
      justifyContent: 'space-between',
      margin: '0',
    },
    languageCTA: {
      background: theme.palette.secondary.main,
      color: '#fff',
      textDecoration: 'none',
      textAlign: 'center',
      display: 'block',
      fontWeight: '700',
      width: '100%',
      borderRadius: '0 8px 8px',
      padding: '12px 0',
      fontSize: '17px',
      lineHeight: '24px',
      margin: '0',
      letterSpacing: '0',
      boxShadow: '0px 5px 20px 0px #0000000D',
      '&:hover': {
        background: 'rgb(163, 0, 58)',
      },
    },
  }
})

const LanguageSwitcher = ({
  hideDivider,
}: { hideDivider?: boolean }) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { language, languages, originalPath } = useI18next();
  const search = typeof window !== 'undefined' ? window?.location?.search : '';
  const [selectedLanguage, setSelectedLanguage] = useState(language);
  const { t } = useTranslation();
  if (languages.length < 2) {
    return null;
  }
  return (
    <>
      <div className={classes.root}>
        {!hideDivider && <div className={classes.divider} />}
        <div className={classes.switcher} onClick={() => { setIsModalOpen(true); }}>
          <LanguageIcon className={classes.icon} />
          <span className={classes.language}>{language.split('-')[0]}</span>
        </div>
      </div>
      <Dialog open={isModalOpen} onClose={() => { setIsModalOpen(false); }}>
        <DialogContent className={classes.modalContent}>
          <h3 className={classes.modalTitle}>{t('languageSwitcher.title')}</h3>
          <RadioGroup className={classes.radioGroup} aria-label="language-radio" name="language" value={selectedLanguage} onChange={(e) => { setSelectedLanguage(e.target.value); }}>
            {languages.map((lang) => {
              return (
                <FormControlLabel
                  className={classes.radioItem}
                  value={lang}
                  control={<Radio checkedIcon={<RadioButtonChecked />} icon={<RadioButtonUnchecked color="primary" />} />}
                  label={languageMap[lang] || t(`languageSwitcher.options.${lang}`)}
                  labelPlacement="start"
                />
              )
            })}
          </RadioGroup>
          <Link className={classes.languageCTA} to={`${originalPath}${search}`} language={selectedLanguage} onClick={() => { setIsModalOpen(false); }}>
            {t('languageSwitcher.CTA')}
          </Link>
        </DialogContent>
      </Dialog >
    </>
  )
}

export default LanguageSwitcher;
