import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import SchemaOrg from './SchemaOrg';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { minimatch } from 'minimatch';
import noIndexPages from './noIndexPages';

export type SEOHelmetProps = {
  title?: string;
  description?: string;
  keywords?: { text: string }[];
  location: Location;
  pageType?: string;
  locale?: string;
  image?: {
    url: string;
    alt: string;
    dimensions: {
      width?: string;
      height?: string;
    };
  },
  datePublished?: string;
  dateModified?: string;
  author?: {
    name: string;
    email: string;
  },
  schemaKey?: string;
  overrideTitle?: boolean;
  htmlAttributes?: any;
}

const SEOHelmet: FC<SEOHelmetProps> = ({
  title,
  description,
  keywords,
  location,
  pageType = 'website',
  image,
  datePublished,
  dateModified,
  author,
  schemaKey,
  locale = 'en-US',
  overrideTitle,
  htmlAttributes = {}
}) => {
  const data = useStaticQuery(query);
  const { t } = useTranslation();
  const fullTitle = overrideTitle ? title : `${t('seo.title')}${title ? ` | ${title}` : ''}`;
  const organization = data.prismicOrganization.data;
  let index = true;
  noIndexPages.forEach((pattern) => {
    if (minimatch(location.pathname, pattern)) {
      index = false;
    }
  });
  return (
    <>
      <Helmet htmlAttributes={{ ...htmlAttributes, lang: locale }}>
        {/* General Tags */}
        <title>{fullTitle}</title>
        <meta name="robots" content={`${index ? `index,follow` : `noindex,nofollow`}`} />
        <meta name="description" content={description || t('seo.description')} />
        {keywords && keywords.length &&
          <meta name="keywords" content={keywords.map(({ text }) => text).join(',')} />
        }
        <link rel="canonical" href={location.href} />
        {/* OpenGraph tags */}
        <meta property="og:url" content={location.href} />
        <meta property="og:type" content={pageType} />
        <meta property="og:locale" content={locale} />
        <meta property="og:site_name" content={data.site.siteMetadata.siteName} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description || t('seo.description')} />
        <meta property="og:image" content={image?.url} />
        <meta property="og:image:alt" content={image?.alt} />
        {image?.dimensions?.width && <meta property="og:image:width" content={image?.dimensions?.width} />}
        {image?.dimensions?.height && <meta property="og:image:height" content={image?.dimensions?.height} />}
        {data.site.social?.fbAppID ? (
          <meta property="fb:app_id" content={data.site.social?.fbAppID} />
        ) : null}
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description || t('seo.description')} />
        <meta name="twitter:image" content={image?.url} />
        <meta name="twitter:image.alt" content={image?.alt} />
        {data.site.social?.twitter ? (
          <meta name="twitter:site" content={data.site.social?.twitter} />
        ) : null}
        {data.site.social?.twitter ? (
          <meta name="twitter:creator" content={data.site.social?.twitter} />
        ) : null}
      </Helmet>
      {/* OpenGraph tags for articles only */}
      {pageType === 'article' ? (
        <Helmet>
          <meta property="article:published_time" content={datePublished} />
          <meta property="article:modified_time" content={dateModified} />
          <meta
            property="article:author:profile.first_name"
            content={author?.name}
          />
        </Helmet>
      ) : null}
      {/* Schema.org tags */}
      <SchemaOrg
        schemaKey={schemaKey}
        isBlogPost={pageType === 'article'}
        url={location.href}
        title={title}
        description={description}
        image={image}
        datePublished={datePublished}
        dateModified={dateModified}
        siteUrl={data.site.siteMetadata.siteUrl}
        siteName={data.site.siteMetadata.siteName}
        author={author}
        organization={{
          url: organization.meta_url.text,
          name: organization.meta_name.text,
          email: organization.meta_email.text,
          logo: {
            url: organization.meta_logo.url,
            alt: organization.meta_logo.alt,
            width: organization.meta_logo.dimensions.width,
            height: organization.meta_logo.dimensions.height,
          },
        }}
      />
    </>
  )
}

export default SEOHelmet;

const query = graphql`
  query AppHelmetQuery {
    site {
      siteMetadata {
        siteName
        siteUrl
        title
        description
      }
    }
    prismicOrganization {
      data {
        meta_name {
          text
        }
        meta_email {
          text
        }
        meta_url {
          text
        }
        meta_logo {
          url
          alt
          dimensions {
            width
            height
          }
        }
      }
    }
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
